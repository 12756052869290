/*******************************
        User Overrides
*******************************/

.ui.inverted.tabular.menu {
  background: var(--main-bg-colour);
  border-bottom: 1px solid var(--lighter-grey);
}

.ui.inverted.tabular.menu .active.item {
  background: var(--main-bg-colour);
  border: 1px solid var(--lighter-grey);
  border-bottom: none;
}

.ui.inverted.tabular.menu .item:not(.active):hover {
  background: none;
}

.ui.inverted.tabular.menu .item {
  margin-bottom: -1px;
}