/*******************************
        User Overrides
*******************************/

.ui.popup {
  clear: both;
}
.ui.popup::before {
  background: var(--medium-grey) !important;
}
.ui.popup.center.right::before {
  box-shadow: -1px 1px 0 0 white !important;
}
.ui.popup.center.left::before {
  box-shadow: 1px -1px 0 0 white !important;
}
.ui.popup.bottom::before {
  box-shadow: -1px -1px 0 0 white !important;
}
.ui.popup.top::before {
  box-shadow: 1px 1px 0 0 white !important;
}