/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown {
  color: var(--main-text-colour) !important;
  background: var(--medium-grey);
}

.ui.dropdown .menu .selected.item, .ui.dropdown.selected, .ui.dropdown .menu .active.item {
  background: var(--light-grey);
  color: var(--main-text-colour);
}

.ui.dropdown .menu > .item {
  color: var(--main-text-colour);
  background: var(--medium-grey);
}

.ui.dropdown .menu > .item:not(.selected):hover {
  color: var(--main-text-colour);
  background: var(--dark-grey);
}

.ui.selection.visible.dropdown > .text:not(.default),
.ui.active.search.dropdown input.search:focus + .text {
  color: #ccc !important;
}

.ui.multiple.search.dropdown > input.search {
  width: inherit !important;
}

.ui.selection.dropdown .menu > .message,
.ui.dropdown .menu > .message:not(.ui) {
  background: var(--medium-grey);
  color: var(--main-text-colour);
}

.ui.selection.dropdown .menu > .item,
.ui.dropdown .menu > .item {
  border-top: none;
}

.ui.selection.dropdown,
.ui.dropdown {
  min-width: 0;
}

.ui.dropdown > .text > .description, .ui.dropdown .menu > .item > .description {
  color: var(--slightly-transparent-main-text-colour);
  font-weight: bold;
}

.ui.dropdown.with-description > .menu > .item > .text {
  margin-right: 120px;
}

@media only screen and (min-width: 992px) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu,
  .ui.search.dropdown .menu,
  .ui.selection.dropdown .menu {
    max-height: 11rem;
  }
}

.ui.dropdown .menu.compact > .item {
  padding: 0.8125rem 1.125rem !important;
}

.ui.dropdown .menu.compact > .header {
  padding: 0.5em 1.125rem;
  background: var(--lighter-grey);
  margin: 0;
  color: var(--main-text-colour);
}