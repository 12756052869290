/*******************************
         Site Overrides
*******************************/

.ui.form .field.error .ui.dropdown,
.ui.form .field.error .ui.dropdown .menu {
  border-color: #d9534f !important;
}

.ui.form .field.error .ui.dropdown .menu .selected.item,
.ui.form .fields.error .field .ui.dropdown .menu .selected.item,
.ui.form .field.error .ui.dropdown .menu .selected.item .text,
.ui.form .fields.error .field .ui.dropdown .menu .selected.item .text {
  background: var(--light-grey) !important;
  color: var(--main-text-colour);
}

.ui.form .field.error .ui.dropdown .menu > .item:hover,
.ui.form .field.error .ui.dropdown .menu > .item:hover .text {
  color: var(--main-text-colour);
  background: var(--dark-grey);
}

.ui.form.inverted .field.error .ui.dropdown .item,
.ui.form.inverted .field.error .ui.dropdown .text:not(.default),
.ui.form.inverted .field.error .ui.dropdown {
  background: var(--medium-grey);
  color: white;
}

.ui.form.inverted .field.error .ui.dropdown .text.default {
  background: var(--medium-grey);
  color: rgba(191, 191, 191, 0.87);
}

.ui.inverted.form input[type=text] {
  color: var(--main-text-colour);
}

.ui.form.inverted .field textarea {
  color: white;
  border-color: rgba(256, 256, 256, 0.1);
  background: var(--medium-grey);
}

.ui.form.inverted .field.error textarea {
  background: var(--medium-grey);
  border-color: #e0b4b4;
  color: white;
}

.ui.form .one.fields>.field, .ui.form .one.fields>.fields {
  width: 100%;
}

.ui.form .disabled.fields .field, .ui.form .disabled.field, .ui.form .field :disabled {
  opacity: 1;
}

.ui.form .field.disabled > label, .ui.form .fields.disabled > label {
  opacity: 1;
}