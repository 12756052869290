/*******************************
         Site Overrides
*******************************/

.ui.input > input {
  background: var(--medium-grey) !important;
  color: var(--main-text-colour) !important;
}

.ui.input {
  color: var(--main-text-colour);
}

.ui[class*="right labeled"].input > input:focus {
  border-right-color: transparent !important;
}