/*******************************
         Site Overrides
*******************************/

.ui.table td.positive, .ui.table tr.positive {
  background: inherit !important;
}

.ui.table td.warning, .ui.table tr.warning {
  background: inherit !important;
}

.ui.table td.error, .ui.table tr.error {
  background: inherit !important;
}

.ui.table td.positive, .ui.table tr.positive {
  color: green !important;
}

.ui.table td.warning, .ui.table tr.warning {
  color: yellow !important
}

.ui.table td.negative, .ui.table tr.negative {
  color: red !important
}

.ui.selectable.table tr.positive:hover, .ui.selectable.table tr:hover td.positive, .ui.table tr td.selectable.positive:hover,
.ui.selectable.table tr.warning:hover, .ui.selectable.table tr:hover td.warning, .ui.table tr td.selectable.warning:hover,
.ui.selectable.table tr.error:hover, .ui.selectable.table tr:hover td.error, .ui.table tr td.selectable.error:hover {
  background: var(--table-hover-bg-colour) !important;
}

.ui.table thead th {
  background: var(--medium-grey);
  color: var(--main-text-colour);
}

.ui.table {
  border: none;
}

.ui.table td.active, .ui.table tr.active {
  background: var(--lighter-grey) !important;
  color: var(--main-text-colour) !important;
}

.ui.inverted.sortable.table thead th.not-sortable {
  cursor: auto;
  white-space: normal;
}

.ui.inverted.sortable.table thead th.not-sortable:hover {
  background: var(--medium-grey);
  background-color: rgba(0, 0, 0, 0.15);
}