.ui.menu:not(.pointing) .item {
  margin: 10px 0px;
  padding: 8px 12px;
  border-radius: 4px;
}

.ui.item.menu:not(.pointing) {
  padding-left: 1em !important;
  padding-right: 1em !important;
  .active.item {
    border: 1px solid #ddd;
  }
}

// make active item in vertical menu more detectable
.ui.vertical.menu .active.item {
  color: darken(@primaryColor, 15%);
  font-weight: bold;
}

.ui.menu .dropdown.item .menu.red {
  background: #db2828;
}

.ui.menu .ui.dropdown.item .menu .item:not(.filtered) {
  color: var(--main-text-colour) !important;
}

.ui.vertical.tabular.menu .active.item {
  background: rgb(197,35,35);
  border: none;
}

.ui.menu.fluid, .ui.vertical.menu.fluid {
  border: none;
}

.ui.vertical.tabular.menu .item {
  border: none;
}

.ui.compact.vertical.menu .item:last-child::before {
  display: none;
}

.ui.menu.tab-menu {
  margin: 0;
  background: var(--header-colour);
  border-bottom: 1px solid var(--lighter-grey);
  border-radius: 0;
  height: 57px;
  min-height: 57px;
}

.ui.menu.tab-menu .item {
  margin: 0;
  border-radius: 0;
}