.ui.button {
  border: 1px solid #ddd;
}

.ui.basic.buttons .button,
.ui.basic.button {
  border: none;
}

.ui.basic.button:focus,
.ui.basic.button:hover {
  text-decoration: underline;
}

.ui.buttons .button:first-child {
  border-left: 1px solid #ddd;
}

.ui.button {
  border: none;
}

.ui.animated.button, .ui.animated.button:hover, .ui.animated.button:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.ui.inverted.primary.button, .ui.inverted.primary.buttons .button {
  color: var(--main-text-colour);
  background: #54c8ff;
}

.ui.animated.button {
  vertical-align: inherit;
}