:root {
  --main-bg-colour: #282828;
  --table-hover-bg-colour: rgba(255,255,255,.00);
  --main-text-colour: white;
  --medium-grey: #333;
  --light-grey: #444;
  --lighter-grey: #555;
  --even-lighter-grey: #666;
  --dark-grey: #222;
  --transparent-white: rgba(256,256,256,0.15);
  --slightly-transparent-main-text-colour: rgba(256,256,256,0.8);
  --header-colour: #1b1c1b;
}

/*
Starting point for light theme:
:root {
  --main-bg-colour: #eee;
  --table-hover-bg-colour: #aaa;
  --main-text-colour: black;
  --medium-grey: #ccc;
  --light-grey: #ddd;
  --dark-grey: #bbb;
}
*/

legend {
  font-family: @fontName;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg-colour);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
}

.npm__react-simple-code-editor__textarea {
  max-height: none !important;
}

.ui.form .field.error .ui.dropdown, .ui.form .field.error .ui.dropdown .item, .ui.form .field.error .ui.dropdown .text, .ui.form .fields.error .field .ui.dropdown, .ui.form .fields.error .field .ui.dropdown .item {
  background: var(--medium-grey);
  color: #e0b4b4;
}

.sortableInputList {
  text-align: right;
  z-index: 10000;
  filter: none !important;
  -webkit-filter: none !important;
}

td.custom-selectable:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

.recharts-tooltip-wrapper {
  visibility: visible !important;
  z-index: 1000;
}

.timelineTick:hover {
  color: var(--main-text-colour) !important;
  cursor: pointer !important;
}

.severity-1 {
  background-image: radial-gradient(var(--transparent-white) 20%, transparent 20%),
      radial-gradient(var(--transparent-white) 20%, transparent 20%) !important;
  background-size: 10px 10px !important;
  background-position: 0 0, 5px 5px !important;
}

.severity-2 {
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255,255,255,.1) 5px, rgba(255,255,255,.1) 10px) !important;
}

.recharts-responsive-container {
  user-select: none;
}

::selection, textarea::selection, input::selection {
  background-color: #3d91ff;
  color: white;
}

.ui.inverted.segment.clndr-calendars-segment {
  width: fit-content;
  border: 1px solid white !important;
  background: var(--medium-grey);
}

.ui.inverted.segment.clndr-calendars-segment:not(.clndr-floating) {
  margin: 0 !important;
}

.force-fluid .field {
  width: 100%;
  margin-bottom: 0 !important;
}

.clndr-calendars-segment.clndr-top.clndr-floating {
  margin-bottom: -1rem !important;
}

.clndr-calendars-wrapper {
  height: 260.5px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.page-content {
  padding: 10px;
}

.ui.loading.segment.transparent-background:before {
  background: transparent;
}

.page-header {
  top: 0px;
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 200;
  background-color: var(--header-colour);
  color: var(--main-text-colour);
  border-bottom: 1px solid var(--lighter-grey);
}

.logo-wrapper {
  position: fixed;
  top: 5px;
  left: 5px;
}