/*******************************
    User Variable Overrides
*******************************/

.ui.statistic>.value, .ui.statistics .statistic>.value {
  font-size: 2rem !important;
}

.ui.mini.statistic > .value, .ui.mini.statistics .statistic > .value {
  font-size: 0.75rem !important;
}