.ui.label:not(.corner) {
  font-family: @fontName;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  background-color: @white;
}

.ui.tag.label:before {
  border-style: solid;
  border-color: #ddd;
  border-width: 1px 0 0 1px;
}

.ui.ribbon.label:after {
  left: -1px;
  border-right-color: darken(white, 5%);
  border-top: 1px solid #ddd;
}

.ui.label:not(.corner) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  padding: .35714286em .78571429em;
  -webkit-box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
  box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
  font-family: inherit !important;
}

.ui.corner.label .icon {
  cursor: inherit;
}

.ui.circular.labels .label, .ui.circular.label {
  min-width: min-content;
  min-height: max-content;
  padding: 0.35714286em 0.78571429em !important;
}