.ui.message {
  border: 1px solid rgba(0, 0, 0, 0.1);

  a {
    text-decoration: underline;
  }
}

.ui.negative.message {
  background-color: var(--medium-grey);
  color: var(--main-text-colour);
}

.ui.info.message {
  background-color: var(--medium-grey);
  color: var(--main-text-colour);
}

.ui.positive.message {
  background-color: var(--medium-grey);
  color: var(--main-text-colour);
}