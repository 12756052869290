/*******************************
    User Variable Overrides
*******************************/

.ui.items.inverted > .item > .content > .header {
    color: rgba(256, 256, 256, 0.85);
}

.ui.items.inverted > .item > .content > .meta {
    color: rgba(256, 256, 256, 0.6);
}

.ui.items.inverted > .item > .content > .description {
    color: rgba(256, 256, 256, 0.87);
}

.item .ui.tiny.image {
    width: 55px;
} 