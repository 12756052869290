/*******************************
         Site Overrides
*******************************/

.ui.grid [class*="right aligned"] {
  text-align: right;
}

.ui.grid.button-bar {
  margin: 0;
  padding: 10px;
  height: 57px;
  background: var(--header-colour);
  border-bottom: 1px solid rgb(85, 85, 85);
}
.ui.grid.button-bar > .column:not(.row) {
  padding: 0px;
}

.ui.grid.no-padding, .ui.grid.no-padding .row, .ui.grid.no-padding .column {
  margin: 0;
  padding: 0;
}

.ui.grid.full-height, .ui.grid.full-height .row, .ui.grid.full-height .column {
  height: 100%;
}