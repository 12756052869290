/*******************************
         Site Overrides
*******************************/

.ui.modal, .ui.active.modal {
  transition: all ease .5s;
}

.ui.modal > .header {
  background: rgb(197,35,35);
  color: var(--main-text-colour);
}

.ui.modal > .content {
  background: var(--main-bg-colour);
  color: var(--main-text-colour);
}

.ui.modal > .actions {
  background: var(--main-bg-colour);
  color: var(--main-text-colour);
}