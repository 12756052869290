/*******************************
         Site Overrides
*******************************/

.ui.right.overlay.sidebar {
  margin-right: -50px !important;
}

.ui.left.overlay.sidebar {
  margin-left: -50px !important;
}

.ui.overlay.sidebar, .ui.overlay.sidebar {
  min-height: 500px;
  max-height: 750px;
  width: auto;
  overflow-y: visible !important;
}