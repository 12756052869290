/*******************************
         Site Overrides
*******************************/

.ui.search > .results {
  background: var(--medium-grey);
}

.ui.category.search > .results .category .results {
  background: var(--medium-grey);
}

.ui.search > .results .result .title {
  color: var(--main-text-colour);
}

.ui.category.search > .results .category > .name {
  background: var(--medium-grey);
  color: var(--main-text-colour);
}

.ui.category.search > .results .category .result:hover, .ui.search > .results .result:hover {
  background: var(--light-grey);
  color: var(--main-text-colour);
}

.ui.search>.results > .message .header {
  color: var(--main-text-colour);
}

.ui.category.search>.results .category .result.active, .ui.search>.results .result.active {
  background: var(--dark-grey)
}

.ui.search>.results .result.active .title {
  color: var(--main-text-colour);
}

.ui.category.search>.results .category.active>.name {
  color: var(--main-text-colour);
}