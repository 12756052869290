/*******************************
         Site Overrides
*******************************/

.ui.secondary.segment {
  color: var(--main-text-colour);
  background: var(--medium-grey);
}

.ui.inverted.segment {
  background: var(--main-bg-colour);
}

.ui.loading.segment:before {
  background: rgba(255,255,255,.2);
}