/*******************************
    User Variable Overrides
*******************************/

.ui.card > .content, .ui.cards > .card > .content {
  background: var(--medium-grey);
}

.ui.card > .content > .header, .ui.cards > .card > .content > .header {
  color: var(--main-text-colour);
}

.ui.card .meta, .ui.cards > .card .meta {
  color: var(--main-text-colour);
}

.ui.card, .ui.cards>.card {
  border-radius: 0.4rem;
  box-shadow: none;
}